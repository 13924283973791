<template>
  <div class="scrollable height-fixed">
    <template v-if="notes.length > 0">
      <div v-for="note in notes" class="note p-3 mb-3" :key="note.id">
        <div class="note-left-container">
          <div class="note-header">
            <p class="added-by">
              {{ stages[note.temp_stage] }}
            </p>
          </div>
          <div class="note-body">
            <span class="note-text">
              -> {{ note.description }}
            </span>
            <div class="note-footer pt-3">
                <p class="date">{{ note.created | getCreatedAt('date') }}</p>
              <p class="time ml-3">{{ note.created | getCreatedAt('time') }}</p>
            </div>
          </div>
        </div>
        <div class="note-remove-container">
          <button class="remove-note-btn py-1 px-2" @click="setDeleteModal(note.id)">
          <span>
            <img alt="Image placeholder" src="/img/icons/buttons/remove.svg">
          </span>
            <span class="pl-1">
            Remove
          </span>
          </button>
        </div>
      </div>
    </template>
    <div v-else class="d-flex align-items-center h-100">
      <h1 :class="{invisible: loading}" class="empty-data-text-font">You don't have any checklist notes.</h1>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ChecklistNotesBody",
  props: ['notes', 'loading'],
  data() {
    return {
      stages: {
        1: 'Pre-Op',
        2: 'Post-Op',
        3: 'Weight Management',
      },
    }
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @param column
     * @returns {string}
     */
    getCreatedAt: function (date, column) {
      return (column === 'time') ? moment.utc(date).local().format('hh:mma')
        : moment.utc(date).local().format('MMM DD, YYYY')
    }
  },
  methods: {
    /**
     * Set the Delete modal
     * @param id
     */
    setDeleteModal(id) {
      this.$emit('setDeleteModal', id)
    }
  }
}
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
}

.height-fixed {
  height: 334px;
}

.note {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border: 2px solid #BDC4CC80;
  border-radius: 8px;
  border-left: 4px solid #172B4D;
}

.note-body {
  word-break: break-word;
}

.note-text {
  font: normal normal normal 14px/26px Open Sans;
  letter-spacing: 0px;
  color: #172B4D;
  opacity: 1;
  white-space: pre-line;
}

.note-footer p {
  display: inline-block;
}

.note-footer p, .added-by {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #8898AA;
  opacity: 1;
}

.remove-note-btn {
  background-color: #EDF0F8;
  color: #F5365C;
  border: none;
  display: flex;
}

.remove-note-btn span {
  display: block !important;
}

.remove-note-btn span:last-child {
  margin-top: 1px;
}
</style>
