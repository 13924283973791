<template>
  <div>
    <card type="default" header-classes="bg-transparent" v-loading="loaders.notes">
      <notes-header :screen="screen" :noteType="internalNote.form.note_type" @openAddNotesModal="openAddNotesModal"/>

      <div class="note-tabs-container">
        <template v-if="screen==='PatientDetail'">
          <el-tabs
            v-model="tabs.selectedNoteType"
            @tab-click="setSelectedNoteType"
            class="tab_main pl-2 pr-2">
            <el-tab-pane label="General Notes" class="mt-3">
              <notes-body :notes="notes" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
            <el-tab-pane label="Nutrition Notes" class="mt-3">
              <notes-body :notes="notes" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
            <el-tab-pane label="Pipeline Notes" class="mt-3">
              <pipeline-notes-body :notes="pipelineNotes" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
            <el-tab-pane label="Checklist Notes" class="mt-3">
              <checklist-notes-body :notes="checklistNotes.data" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
          </el-tabs>
        </template>
        <template v-else-if="screen==='NutritionReport'">
          <div class="pl-2 pr-2 mt-4">
            <notes-body :notes="notes" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
          </div>
        </template>
        <template v-else-if="screen==='Checklists'">
          <el-tabs
            v-model="tabs.selectedNoteType"
            @tab-click="setChecklistPageNoteType"
            class="checklist-page-notes-tab px-2">
            <el-tab-pane label="Checklist Notes" class="mt-3">
              <checklist-notes-body :notes="checklistNotes.data" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
            <el-tab-pane label="Pipeline Notes" class="mt-3">
              <pipeline-notes-body :notes="pipelineNotes" :loading="loaders.notes" @setDeleteModal="setDeleteModal"/>
            </el-tab-pane>
          </el-tabs>
        </template>

        <template v-if="internalNote.form.note_type !== 'pipeline_notes' && internalNote.form.note_type !== 'checklist_notes'">
        <notes-footer :notes="notes" @changePage="changePage" @changedPagination="changedPagination"/>
        </template>
      </div>

    </card>
    <template v-if="internalNote.form.note_type==='pipeline_notes'">
      <delete-pipeline-notes-modal
        ref="deletePipelineNotesModal"
        :loader="loaders.modal"
        :id="pipelineNotes.deleteId"
        :pipeline-notes="pipelineNotes"
        @setModalLoader="setModalLoader"
        @setNoteStatus="getPipelineNotes(1)"
      />

    </template>
    <template v-else-if="internalNote.form.note_type === 'checklist_notes'">
      <delete-checklist-notes-modal
        ref="deleteChecklistNotesModal"
        :loader="loaders.modal"
        @deleteChecklistNote="deleteChecklistNote"
      />

      <create-checklist-notes-modal
        ref="createChecklistNotesModalRef"
        :loader="loaders.modal"
        :patientId="patient_id"
        :type="stageType"
        @setNotesStates="getStageWiseChecklistNotes"
        @setModalLoader="setModalLoader"
      />
    </template>
    <template v-else>
      <!-- Edit Modal -->
      <create-notes-modal
        ref="createNotesModalRef"
        :currentAction="currentAction"
        :loader="loaders.modal"
        :modal="modals.notes"
        :note_type="internalNote.form.note_type"
        :paginate="internalNote.form.pagination"
        @setInternalNotesStates="setInternalNotesStates"
        @setModalLoader="setModalLoader"
      />
      <!-- Edit Modal Finished -->

      <!-- Delete Modal -->
      <delete-notes-modal
        ref="deleteNotesModal"
        :loader="loaders.modal"
        :id="internalNote.delete_id"
        :patient_id="internalNote.form.patient_id"
        :note_type="internalNote.form.note_type"
        :paginate="internalNote.form.pagination"
        @setInternalNotesStates="setInternalNotesStates"
        @setModalLoader="setModalLoader"
      />
      <!-- Delete Modal Finished -->
    </template>


  </div>
</template>

<script>
import NotesHeader from "@/views/Components/Notes/NotesHeader";
import NotesBody from "@/views/Components/Notes/NotesBody";
import NotesFooter from "@/views/Components/Notes/NotesFooter";
import CreateNotesModal from "@/views/Components/Notes/CreateNotesModal";
import DeleteNotesModal from "@/views/Components/Notes/DeleteNotesModal";
import PipelineNotesBody from "./PipelineNotesBody";
import DeletePipelineNotesModal from "./DeletePipelineNotesModal";
import ChecklistNotesBody from "@/views/Components/Notes/ChecklistNotesBody";
import DeleteChecklistNotesModal from "@/views/Components/Notes/DeleteChecklistNotesModal";
import CreateChecklistNotesModal from "@/views/Components/Noutes/CreateNotesModal";

export default {
  name: "InternalNotes",
  props: ['screen', 'stageType'],
  components: {
    DeletePipelineNotesModal,
    NotesHeader,
    NotesBody,
    NotesFooter,
    CreateNotesModal,
    DeleteNotesModal,
    PipelineNotesBody,
    ChecklistNotesBody,
    DeleteChecklistNotesModal,
    CreateChecklistNotesModal
  },
  data() {
    return {
      patient_id: this.$route.params.id,
      loaders: {
        modal: false,
        notes: false,
      },
      modals: {
        notes: false,
        delete: false,
      },
      internalNote: {
        form: {
          patient_id: this.$route.params.id,
          note: '',
          note_type: 'general_note',
          pagination: 2,
        },
        delete_id: '',
      },
      tabs: {
        selectedNoteType: '',
      },
      notes: {
        user: {
          first_name: '',
          last_name: '',
        },
        data: [],
        pagination: {},
      },
      pipelineNotes : {
        data : [],
        pagination : {
          page:1,
          currentPage:1,
          perPage:20,
          total:1,
        },
        deleteId : '',
      },
      checklistNotes: {
        data: [],
        deleteId: '',
      },
      currentAction: 'Add'
    }
  },
  watch: {
    stageType(newValue, oldValue) {
      this.setChecklistPageNoteType()
    }
  },
  methods: {
    /**
     * Delete a Checklist Note
     */
    deleteChecklistNote() {
      let vm = this
      vm.loaders.notes = true
      const payload = {
        id: vm.checklistNotes.deleteId
      }
      vm.$store.dispatch('_deleteChecklistNote', payload)
      .then(response => {
        vm.$notify.success({
          title: 'Checklist Notes',
          message: 'Checklist note deleted successfully'
        })
        if (this.screen === 'Checklists') {
          vm.getStageWiseChecklistNotes()
        } else {
          vm.getChecklistNotes()
        }
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Checklist Notes',
          message: message
        })
      })
      .finally(() => {
        this.$store.state.modals.deleteChecklistNotesModal = false
        vm.loaders.notes = false
      })
    },

    changePage(page) {
        this.getInternalNotes(page);
    },

    setModalLoader(state) {
      this.loaders.modal = state;
    },

    changedPagination(pagination) {
      if (this.screen === 'PatientDetail' && this.tabs.selectedNoteType === 'pipeline_notes'  ) {
        //custom code
      }
      else {
        this.internalNote.form.pagination = pagination;
        this.getInternalNotes(1);
      }
    },

    setDeleteModal(id) {
      if (this.internalNote.form.note_type === 'pipeline_notes' ) {
        this.$store.state.modals.deletePipelineNotesModal = true;
        this.pipelineNotes.deleteId = id;
      } else if(this.internalNote.form.note_type === 'checklist_notes') {
        this.$store.state.modals.deleteChecklistNotesModal = true;
        this.checklistNotes.deleteId = id;
      } else {
        this.$store.state.modals.deleteNotesModal = true;
        this.internalNote.delete_id = id;
      }
    },

    openAddNotesModal() {
      this.$store.state.modals.createNotesModal = true
      if (this.screen==='Checklists') {
        this.$refs.createChecklistNotesModalRef.$refs.notesModal.reset()
      } else {
        this.$refs.createNotesModalRef.$refs.internalNotesModal.reset()
      }
    },

    setTabBorder() {
      let tabs = document.getElementsByClassName('el-tabs__active-bar')[0];
    },

    setInternalNotesStates(response) {
      this.notes.user = response.user;
      this.notes.data = response.notes.data;
      this.notes.pagination = response.notes;
      this.internalNote.form.note = '';
    },

    setChecklistPageNoteType() {
      if (this.tabs.selectedNoteType === '0'){
        this.internalNote.form.note_type = 'checklist_notes';
        this.getStageWiseChecklistNotes()
      } else {
        this.internalNote.form.note_type = 'pipeline_notes';
        this.getPipelineNotes(1)
      }
    },

    setSelectedNoteType() {
      if (this.tabs.selectedNoteType === '0') {
        this.internalNote.form.note_type = 'general_note';
        this.getInternalNotes(1)
      }
      else if (this.tabs.selectedNoteType === '1') {
        this.internalNote.form.note_type = 'nutrition_note';
        this.getInternalNotes(1)
      } else if (this.tabs.selectedNoteType === '2'){
        this.internalNote.form.note_type = 'pipeline_notes';
        this.getPipelineNotes(1)
      } else {
        this.internalNote.form.note_type = 'checklist_notes';
        this.getChecklistNotes()
      }
    },

    /**
     * Get All checklist notes
     */
    getChecklistNotes() {
      let vm = this
      vm.loaders.notes = true
      const payload = {
        patient_id: vm.patient_id
      }
      vm.$store.dispatch('_getAllChecklistNotes', payload)
      .then(response => {
        vm.checklistNotes.data = response.data.data
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Checklist Notes',
          message: message
        })
      })
      .finally(() => {
        vm.loaders.notes = false
      })
    },

    /**
     * Get checklist notes by stage
     */
    getStageWiseChecklistNotes() {
      let vm = this
      vm.loaders.notes = true
      vm.checklistNotes.data = []
      const params = {
        patient_id: this.$route.params.id,
        type: vm.stageType ?? 1
      }
      vm.$store.dispatch('_getChecklistNotes', params)
        .then(response => {
          vm.checklistNotes.data = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Checklist Notes',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.notes = false
        })
    },

    getInternalNotes(page) {
      let vm = this;
      vm.loaders.notes = true;
      if (this.screen === 'NutritionReport') {
        this.internalNote.form.note_type = 'nutrition_note';
      }
      vm.$store.dispatch('_getInternalNotes', {
        patient_id: vm.patient_id,
        pagination: vm.internalNote.form.pagination,
        note_type: vm.internalNote.form.note_type,
        page: page,
      }).then(response => {
        vm.setInternalNotesStates(response);
      }).catch(error => {
        if (error.response.status != 401) {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Internal Notes',
            message: message
          });
        }
      }).finally(() => {
        vm.loaders.notes = false;
      })
    },

    getPipelineNotes(page) {
      let vm = this;
      vm.loaders.notes = true;
      axios.get(this.$store.getters.getBaseUrl + '/api/pipeline-notes?patient_id=' + this.patient_id)
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
        // console.log('pipeline notes',response.data.data);
        vm.pipelineNotes.data = response.data.data;
        // vm.pipelineNotes.pagination = response.data;
        // vm.pipelineNotes.pagination.page = response.data.data.current_page;
        // vm.pipelineNotes.pagination.perPage = response.data.data.per_page;
        // vm.pipelineNotes.pagination.total = response.data.data.total;
        vm.loaders.card = false;
      })
        .catch(error => {
        console.log(error.response);
        vm.loaders.notes = false;
      }).finally(() => {
        vm.loaders.notes = false;
      });
    },
  },
  mounted() {
    if (this.screen === 'Checklists') {
      this.tabs.selectedNoteType = '0'
      this.internalNote.form.note_type = 'checklist_notes'
      this.getStageWiseChecklistNotes()
    } else {
      this.getInternalNotes(1);
    }
    this.setTabBorder();
  },
}
</script>

<style scoped>
</style>

<style>
.note-tabs-container .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}

.note-tabs-container .el-tabs__item {
  border: none !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-transform: uppercase!important;
  font-weight: bold;
}

.note-tabs-container .el-tabs--border-card > .el-tabs__header {
  border: none !important;
}

.note-tabs-container .el-tabs__item.is-active {
  color: #2F597A !important;
}

.note-tabs-container .el-tabs__active-bar {
  background-color: #2F597A !important;
}

.el-tabs__nav-wrap::after {
  background-color: #e5e2e2 !important;
  height: 1px;
}

.checklist-page-notes-tab .el-tabs__nav {
  transform: translateX(10px) !important;
}

.el-tabs__item:hover {
  color: #172B4D !important;
}

.note-tabs-container .el-tabs--border-card {
  border: none;
  box-shadow: none;
}

.note-tabs-container .el-tabs--border-card > .el-tabs__header {
  background-color: transparent !important;
}
</style>
