<template>
  <div>
    <modal v-loading="loader" :show.sync="$store.state.modals.deletePipelineNotesModal">
      <template slot="header">
        <h5 class="modal-title">Are you sure?</h5>
      </template>
      <div class="content mt--4 ml--1">
        You want to remove this Pipeline note.
      </div>
      <template slot="footer">
        <base-button type="secondary" class="btn-gray" @click="$store.state.modals.deletePipelineNotesModal=false">Cancel</base-button>
        <base-button type="danger" @click="deleteNote">Remove</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: "DeletePipelineNotesModal",
  props: ['loader', 'id','pipelineNotes'],
  methods: {
    deleteNote() {
      let th = this;
      th.$emit('setModalLoader', true)
      axios.delete(this.$store.getters.getBaseUrl + '/api/pipeline-notes/' + this.id).then((response) => {
        th.$store.commit('toggleSessionCheck', true)
        th.$store.commit('updateSessionTime', 0)
        th.$notify.success({
          title: 'Success',
          message: 'Pipeline Note Deleted'
        });
        if (th.pipelineNotes && th.pipelineNotes.length > 0) {
          let res = th.pipelineNotes.data.findIndex((note) => note.id === th.id);
          if (res !== -1)
            th.pipelineNotes.data.splice(res, 1);
        }
        th.$store.state.modals.deletePipelineNotesModal=false;
        th.$emit('setNoteStatus')
      }).catch(error => {
        let message = 'Something went wrong please try again in few minutes.';
        if (error.response) {
          let errors = error.response.data.errors;
          let firstError = Object.keys(errors)[0];
          message = errors[firstError][0];
        }
        th.$notify.error({
          title: 'Pipeline Notes',
          message: message
        });
      }).finally(() => {
        th.$emit('setModalLoader', false)
      });
    }
  },
}
</script>

<style scoped>

</style>
