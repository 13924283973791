<template>
  <modal v-loading="loader" :show.sync="$store.state.modals.deleteChecklistNotesModal">
    <template slot="header">
      <h5 class="modal-title">Are you sure?</h5>
    </template>
    <div class="content mt--4 ml--1">
      You want to remove this Checklist Note.
    </div>
    <template slot="footer">
      <base-button type="secondary" class="btn-gray" @click="hideDeleteModal">Cancel</base-button>
      <base-button type="danger" @click="deleteChecklistNote">Remove</base-button>
    </template>
  </modal>
</template>

<script>
export default {
  name: "DeleteChecklistNotesModal",
  props: ['loader'],
  methods: {
    /**
     * Hide the Delete checklist note modal
     */
    hideDeleteModal() {
      this.$store.state.modals.deleteChecklistNotesModal = false
    },

    /**
     * Delete checklist note
     */
    deleteChecklistNote() {
      this.$emit('deleteChecklistNote')
    }
  }
}
</script>

<style scoped>

</style>
