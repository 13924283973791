import { render, staticRenderFns } from "./DeleteChecklistNotesModal.vue?vue&type=template&id=54b41db0&scoped=true&"
import script from "./DeleteChecklistNotesModal.vue?vue&type=script&lang=js&"
export * from "./DeleteChecklistNotesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54b41db0",
  null
  
)

export default component.exports