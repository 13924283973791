<template>
  <div>
    <modal v-loading="loader" :show.sync="$store.state.modals.createNotesModal" @close="closeModal">
      <h6 slot="header" class="modal-title" id="modal-title-default">Add Note</h6>

      <validation-observer v-slot="{handleSubmit}" ref="notesModal">
        <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">

          <div class="mt--4">
            <base-input label="Note" :rules="{required: true}" name="note">
              <textarea v-model="note.description" class="form-control" rows="4"/>
            </base-input>
          </div>

          <div class="float-right mt-4">
            <base-button type="secondary" class="ml-auto btn-gray" @click="closeModal">
              Cancel
            </base-button>
            <base-button native-type="submit" type="submit" class="btn-primary-action">
              Add Note
            </base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
export default {
  name: "CreateNotesModal",
  props: ['loader', 'patientId','type'],
  // set modal prop to data
  data() {
    return {
      note : {
        patientId: this.patientId,
        type: this.type,
        description:'',
      }
    }
  },
  methods: {
    submitForm() {
        this.addNote();
    },

    closeModal() {
      this.$store.state.modals.createNotesModal = false;
      this.note.description = '';
      this.$refs.notesModal.reset();
      this.$emit('closeModal')
    },

    addNote() {
      let vm = this;
      this.$emit('setModalLoader', true);
      this.$store.dispatch('_addNote', vm.note).then(response => {
        vm.closeModal();
        vm.$emit('setNotesStates', response);
        vm.$notify.success({
          title: 'Notes',
          message: 'Note Added Successfully'
        });
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Notes',
          message: message
        });
      }).finally(() => {
        vm.$emit('closeModal');
        vm.$emit('setModalLoader', false);
      })
    },
  },

  mounted() {
    this.$root.$on('updateType',(data)=>{
      this.note.type = data;
    });
  },
}
</script>

<style scoped>

</style>
