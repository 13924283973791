<template>
  <div>
    <modal v-loading="loader" :show.sync="$store.state.modals.createNotesModal" @close="closeModal">
      <h6 slot="header" class="modal-title" id="modal-title-default">{{ currentAction }} Note</h6>

      <validation-observer v-slot="{handleSubmit}" ref="internalNotesModal">
        <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">

          <div class="mt--4">
            <base-input label="Note" :rules="{required: true}" name="note">
              <textarea
                v-model="form.note"
                class="form-control"
                rows="4">
              </textarea>
            </base-input>
          </div>

          <div class="float-right mt-4">
            <base-button type="secondary" class="ml-auto btn-gray" @click="closeModal">
              Cancel
            </base-button>
            <base-button native-type="submit" type="submit" class="btn-primary-action">
              {{ currentAction }} Note
            </base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
  </div>
</template>

<script>
export default {
  name: "CreateNotesModal",
  props: ['loader', 'modal', 'currentAction', 'note_type', 'paginate'],
  // set modal prop to data
  data() {
    return {
      form: {
        patient_id: this.$route.params.id,
        note: '',
        note_type: '',
        pagination: '',
      },
    }
  },
  methods: {
    submitForm() {
      if (this.currentAction === 'Add') {
        this.addInternalNotes();
      }
    },
    closeModal() {
      this.$store.state.modals.createNotesModal = false;
      this.form.note = '';
      this.$refs.internalNotesModal.reset();
      this.$emit('closeModal')
    },
    addInternalNotes() {
      let vm = this;
      this.$emit('setModalLoader', true);
      vm.form.note_type = this.note_type;
      vm.form.pagination = this.paginate;
      this.$store.dispatch('_addInternalNote', vm.form).then(response => {
        vm.closeModal();
        vm.$emit('setInternalNotesStates', response);
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Internal Notes',
          message: message
        });
      }).finally(() => {
        vm.$emit('closeModal');
        vm.$emit('setModalLoader', false);
      })
    },

  }
}
</script>

<style scoped>

</style>
