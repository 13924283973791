<template>
  <div>
    <modal v-loading="loader" :show.sync="$store.state.modals.deleteNotesModal">
      <template slot="header">
        <h5 class="modal-title">Are you sure?</h5>
      </template>
      <div class="content mt--4 ml--1">
        You want to remove this note.
      </div>
      <template slot="footer">
        <base-button type="secondary" class="btn-gray" @click="$store.state.modals.deleteNotesModal=false">Cancel</base-button>
        <base-button type="danger" @click="deleteNote">Remove</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: "DeleteNotesModal",
  props: ['loader', 'id', 'patient_id', 'note_type', 'paginate'],
  methods: {
    deleteNote() {
      let vm = this;
      vm.$emit('setModalLoader', true);
      this.$store.dispatch('_deleteInternalNote', {
        id: this.id,
        patient_id: this.patient_id,
        note_type: this.note_type,
        pagination: this.paginate,
      }).then(response => {
        vm.$emit('setInternalNotesStates', response);
      }).catch(error => {
        console.log(error);
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Internal Notes',
          message: message
        });
      }).finally(() => {
        vm.$store.state.modals.deleteNotesModal = false
        vm.$emit('setModalLoader', false);
      })
    }
  },
}
</script>

<style scoped>

</style>
