<template>
  <div>
    <div class="d-flex justify-content-between">

      <div class="d-flex ml-2 mt-2">
        <div class="current-pagination">
          {{ getCurrentData }}
        </div>
        <div class="pagination-separator ml-3 mr-3">
          <span class="pagination-separator">|</span>
        </div>
        <div class="pagination-changer mt--1">
          Items per page
          <el-select
            class="select-primary pagination-select paginated-table-select ml-2"
            @change="changedPagination"
            v-model="paginationSelected"
            size="mini"
          >
            <el-option
              class="select-primary"
              v-for="(page, index) in pagination"
              :key="index"
              :label="page"
              :value="page"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <base-pagination
        class="pagination-no-border pointer float-right mr-3 mt-2"
        v-model="notes.pagination.current_page"
        size="sm"
        :per-page="parseInt(notes.pagination.per_page)"
        :total="notes.pagination.total"
        @input="changePage"
      />
    </div>

  </div>
</template>

<script>
import {BasePagination} from "@/components";

export default {
  name: "NotesFooter",
  props: ['notes','defaultPagination','paginationValues'],
  components: {
    BasePagination,
  },
  mounted() {
    if (this.defaultPagination){
      this.paginationSelected = this.defaultPagination
    }
    if (this.paginationValues){
      this.pagination = this.paginationValues
    }
  },
  data() {
    return {
      pagination: ['2', '5', '10', '15', '20'],
      paginationSelected: '2',
    }
  },
  computed: {
    getCurrentData() {
      return `Page ${this.notes.pagination.current_page} of ${this.notes.pagination.last_page}`
    }
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    changedPagination(value) {
      this.$emit('changedPagination', value);
    },
  }
}
</script>

<style>
.pagination-changer .el-input {
  /*width: 75px!important;*/
}

.pagination-changer .el-select .el-input .el-input__inner {
  height: auto!important;
  /*width: 72px;*/
}
</style>
